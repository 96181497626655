import React from 'react';

const Intro: React.FC = () => {
  return (
    <div id="about">
      <h1>About Me</h1>
      <p>
        I&apos;m Brandon Dong, a full-stack web developer from Riverside, CA. I
        have skills in a variety of front-end and back-end technologies, and I
        primarily work in React.js and Express.js.
      </p>
      <p>
        After receiving my Bachelor&apos;s in Statistics, I spent over five
        years in the retail industry, developing a wide array of skills in
        problem-solving, interpersonal communication, and user-oriented design.
        I&apos;m excited to bring these skills to my next role and to continue
        to build fun and exciting projects.
      </p>
      <p>
        If I&apos;m not programming for work or education, I&apos;m usually
        programming for fun, such as creating Minecraft mods. I also like to
        game, particularly role-playing games or board games.
      </p>
    </div>
  );
};

export default Intro;
